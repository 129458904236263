import * as React from 'react';
import { DozukiHeader, DozukiHeaderProps } from '@dozuki/web-js/components';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';
import { apiClient } from 'Shared/api_client';
import { io } from 'socket.io-client';

type DozukiHeaderComponentProps = Pick<
   DozukiHeaderProps,
   | 'siteLogoUrl'
   | 'siteTitle'
   | 'theme'
   | 'user'
   | 'permissions'
   | 'userTranslationSettings'
   | 'siteConfiguration'
   | 'showCustomHeader'
   | 'defaultSearchDocType'
> & {
   initialNotifications: DozukiHeaderProps['notifications'];
   initialTotalUnreadNotifications: DozukiHeaderProps['totalUnreadNotifications'];
   realtime: {
      enabled: boolean;
      host: string;
      room: string;
   };
};

const onRedirect = (url: string) => {
   window.location.href = url;
};

function DozukiHeaderComponent({
   initialNotifications,
   initialTotalUnreadNotifications,
   realtime,
   ...props
}: DozukiHeaderComponentProps) {
   const [notifications, setNotifications] = React.useState(initialNotifications);
   const [totalUnreadNotifications, setTotalUnreadNotifications] = React.useState(
      initialTotalUnreadNotifications
   );

   function handleMarkNotificationAsRead(notificationid: string) {
      setTotalUnreadNotifications(prevTotalUnreadNotifications => prevTotalUnreadNotifications - 1);
      setNotifications(
         notifications.filter(notification => notification.notificationid !== notificationid)
      );

      apiClient.get(`notifications?ids=${notificationid}`);
   }

   function handleMarkAllNotificationsAsRead() {
      setTotalUnreadNotifications(0);
      setNotifications([]);

      apiClient.delete(`notifications/all`);
   }

   React.useEffect(() => {
      let socket;
      if (realtime.enabled && realtime.room) {
         socket = io(realtime.host, {
            secure: false,
            // Try websocket first, since most users will be fine with it.
            transports: ['websocket', 'polling'],
         });

         // After connecting, subscribe to the room specific to the current user.
         socket.on('connect', function () {
            socket.emit('subscribe', { room: realtime.room });
         });

         // When a new notification is received, add it to the dropdown
         socket.on('notification', function (notification) {
            switch (notification.event) {
               case 'notification':
                  // Update the count on the dropdown.
                  setTotalUnreadNotifications(
                     prevTotalUnreadNotifications => prevTotalUnreadNotifications + 1
                  );
                  // Add the notification to the dropdown
                  setNotifications(notifications => {
                     let newNotifications = [notification.data, ...notifications];
                     // Cap shown notifications to 10
                     if (newNotifications.length > 10) {
                        newNotifications = newNotifications.slice(0, 10);
                     }
                     return newNotifications;
                  });
                  break;
            }
         });
      }
      return () => {
         if (socket) {
            socket.close();
         }
      };
   }, []);

   const showCustomHeader = props?.showCustomHeader;
   const theme = showCustomHeader ? 'white' : props?.theme;
   const siteLogoUrl = showCustomHeader ? undefined : props?.siteLogoUrl;
   const siteTitle = showCustomHeader ? undefined : props?.siteTitle;
   const defaultSearchDocType = showCustomHeader ? 'all' : props?.defaultSearchDocType;

   return (
      <DozukiHeader
         {...props}
         theme={theme}
         siteLogoUrl={siteLogoUrl}
         siteTitle={siteTitle}
         _js={_js}
         onRedirect={onRedirect}
         notifications={notifications}
         totalUnreadNotifications={totalUnreadNotifications}
         onMarkNotificationAsRead={handleMarkNotificationAsRead}
         onMarkAllNotificationsAsRead={handleMarkAllNotificationsAsRead}
         onUserTranslationsSettingsChange={handleUserTranslationsSettingsChange}
         defaultSearchDocType={defaultSearchDocType}
      />
   );
}

async function handleUserTranslationsSettingsChange(value) {
   await apiClient.post('/translations/settings', value);
   window.location.reload();
}

initializeChakraComponent('DozukiHeaderComponent', DozukiHeaderComponent);
